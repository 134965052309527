<template>
  <BasePageContent>
    <WarehouseDetail :uuid="uuid"></WarehouseDetail>
  </BasePageContent>
</template>

<script>
import WarehouseDetail from "@/components/ui/warehouse/WarehouseDetail.vue";
export default {
  components: { WarehouseDetail },
  props: {
    uuid: {
      type: String,
    },
  },
};
</script>